import React, { useState } from 'react';
import User from '../types/User';
import Header from './template/Header';

function App() {
  const [ user, setUser ] = useState<Partial<User>>({});

  let page = <main><button onClick={() => setUser({ username: 'Mirko', avatar: 'https://www.gravatar.com/avatar/7673ae0a007974f5f68118287aa90e72' })}>Login</button></main>;
  if (user.username) {
    page = <main>Ciao {user.username}</main>;
  }

  return (
    <>
      <Header user={user} setUser={setUser} />
      { page }
    </>
  );
}

export default App;
