import React from 'react';
import User from '../../types/User';
import './Header.css';

interface HeaderProps {
    user: Partial<User>;
    setUser: React.Dispatch<React.SetStateAction<Partial<User>>>
}
const Header = ({ user, setUser } : HeaderProps) => (
    <header className="app-header">
        <h1>Managias</h1>
        <nav>
            { user.username && user.avatar && <img className="app-header__avatar" src={user.avatar} alt={user.username} onClick={() => setUser({})} /> }
        </nav>
    </header>
);

export default Header;
